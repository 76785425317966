import React, { Suspense } from "react";
import { Route, Routes } from "react-router";
import FamilyTreePage from "../Pages/FamilyTree";
import HomePage from "../Pages/HomePage";
import { ComingSoon } from "../Pages/ComingSoon";
import { HistoryPage } from "../Pages/History";

const Spinner = () => <p>Spinner</p>;

const AppRouter = () => {
  return (
    <>
      <Suspense fallback={<Spinner />} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/family-tree" element={<FamilyTreePage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/contact" element={<ComingSoon />} />
        <Route path="/customs" element={<ComingSoon />} />
      </Routes>
    </>
  );
};

export default AppRouter;
