import React, { useEffect, useState } from 'react';
import PinchZoomPan from '../PinchZoomPan/PinchZoomPan';
import FamilyTree from '../FamilyTree/FamilyTree';
import FamilyNode from '../FamilyNode/FamilyNode';
import { Node, ExtNode } from 'relatives-tree/lib/types';
import { ExtMember } from '../../types'
import nodes from './../../data/node.json'
import styles from './../App/App.module.css';

import { useNodeService } from '../Services/NodeService';
import { Typography } from '@mui/material';

const WIDTH = 190;
const HEIGHT = 220;

const FamilyTreePage = () => {
    const [rootId, setRootId] = useState<string>(nodes ? nodes[0].id : '1');
    // const [nodes, setNodes] = useState<Node[]>();
    const { getNodes } = useNodeService();

    const onResetClick = () => setRootId(nodes ? nodes[0].id : '1');

    const getTreeNodes = async () => {
      const data = await getNodes();
      console.log(data);
      // setNodes(data);
    };

    useEffect(() => {
      getTreeNodes();
    }, []);

    return (
      <div className={styles.root}>
        <header className={styles.header}>
          <Typography variant='h6' className={styles.title}>
            Hierical Family Tree
          </Typography>
          {rootId !== (nodes ? nodes[0].id: '1') && (
          <div className={styles.reset} onClick={onResetClick}>
            Reset Tree
          </div>
        )}
        </header>
        {nodes && rootId && (
          <PinchZoomPan
            min={0.1}
            max={2.5}
            captureWheel
            className={styles.wrapper}
          >
            <FamilyTree
              nodes={nodes as Node[]}
              rootId={rootId}
              width={WIDTH}
              height={HEIGHT}
              className={styles.tree}
              renderNode={(node: ExtNode) => (
                <FamilyNode
                  key={node.id}
                  node={node as ExtMember}
                  isRoot={node.id === rootId}
                  onClick={setRootId}
                  left={node.left * (WIDTH / 2) - 15}
                  top={node.top * (HEIGHT / 2) + 65}
                />
              )}
            />
          </PinchZoomPan>
        )}
      </div>
    );
}

export default FamilyTreePage