import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTrans from "./en-US/enUSTranslations.json";
import xsTrans from "./xs/xsTranslations.json";

export const setupTranslations = () => {
  const resources = {
    enUS: {
      common: enTrans,
    },
    xs: {
      common: xsTrans,
    },
  } as const;

  i18n.use(initReactI18next).init({
    lng: "xs",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed. React does escaping to prevent XSS for us
    },
    resources,
    fallbackLng: "xs",
  });
};
