import LanguageIcon from "@mui/icons-material/Language";
import { useContext } from "react";

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  LangaugeContext,
  Language,
  Supported_Locales,
} from "../../providers/LangaugeProvider";
import { useTranslation } from "react-i18next";

export const LanguagePicker = () => {
  const supportedLocales: Language[] = Supported_Locales;
  const { value, display, upsertLanguage } = useContext(LangaugeContext);
  const { i18n } = useTranslation();
  const updateSettings = async (event: SelectChangeEvent) => {
    const _locale = supportedLocales.find(
      (x) => x.value === event.target.value
    );
    upsertLanguage({ value: _locale!.value, display: _locale!.display });
    i18n.changeLanguage(_locale!.value);
  };

  return (
    <FormControl variant="standard">
      <Select
        id="language-dropdown"
        value={value}
        label="Langauge"
        onChange={updateSettings}
        disableUnderline={true}
        MenuProps={{
          style: {
            paddingLeft: "15px",
            marginTop: "10px",
            borderWidth: "3",
            maxHeight: "400px",
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          PaperProps: {
            style: {
              borderRadius: "4px",
              boxShadow:
                "0 8px 10px 1px rgba(40,60,80,0.1), 0 3px 14px 2px rgba(40,60,80,0.2), 0 5px 5px -3px rgba(40,60,80,0.2)",
            },
          },
        }}
      >
        {supportedLocales.map((key) => {
          return (
            <MenuItem key={key.value} value={key.value}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs>
                  <LanguageIcon />
                </Grid>
                <Grid item xs>
                  {key.display}
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
