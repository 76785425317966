import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  p1,
  p10,
  p11,
  p12,
  p13,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
} from "./../../data/history";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(() => ({
  mainTitle: {
    fontSize: `30px`,
    fontWeight: "bold",
    textAlign: "center",
  },
  subTitle: {
    fontSize: `12px`,
  },
}));

export const HistoryPage = () => {
  const classes = useStyles();

  const { t: translateText } = useTranslation();
  return (
    <Box sx={{ width: "100%", p: { xs: 1, md: 4, lg: 10 } }}>
      <Box
        flexShrink={1}
        textAlign={"justify"}
        justifyContent={"center"}
        overflow={"scroll"}
      >
        <Grid container direction={"column"}>
          <Grid item md={2}>
            <Typography
              variant="h4"
              pt={2}
              className={classes.classes.mainTitle}
            >
              {translateText(`heritage`)}
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.classes.subTitle}></Typography>
            <Typography pb={2}>{p1}</Typography>
            <Typography pb={2}>{p2}</Typography>
            <Typography pb={2}>{p3}</Typography>
            <Typography pb={2}>{p4}</Typography>
            <Typography pb={2}>{p5}</Typography>
            <Typography pb={2}>{p6}</Typography>
            <Typography pb={2}>{p7}</Typography>
            <Typography pb={2}>{p8}</Typography>
            <Typography pb={2}>{p9}</Typography>
            <Typography pb={2}>{p10}</Typography>
            <Typography pb={2}>{p11}</Typography>
            <Typography pb={2}>{p12}</Typography>
            <Typography pb={2}>{p13}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
