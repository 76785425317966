import { Node, ExtNode } from 'relatives-tree/lib/types';
import { request } from './Networking/ApiService';

export const useNodeService = () => {
    const getNodes = async () : Promise<Node[]> => {
        return await request<Node[]>('https://localhost:7209/api/People',{method:'GET',headers:{'Access-Control-Allow-Origin': '*','Content-Type': 'application/json'}})
    }
    return {
        getNodes
    }
}
