import React from "react";
import Typewriter from "typewriter-effect";
import { clanNames } from "../Constants";
import { Typography } from "@mui/material";

const Type = () => {
  return (
    <Typography variant="h4">
      <Typewriter
        options={{
          strings: clanNames,
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
        }}
      />
    </Typography>
  );
};

export default Type;
