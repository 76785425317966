import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
// import useMediaQuery from "@mui/material/useMediaQuery";

const bkground = require("./../../assets/ambrose.jpg");
const snippet = require("./../../assets/orginSnippet.png");

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HomeCard() {
  const [expanded, setExpanded] = React.useState(false);
  const { t: translateText } = useTranslation();
  // const matches = useMediaQuery("(min-width:600px)");

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card variant="outlined" sx={{ height: "auto" }}>
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign={"center"}
            >
              {translateText("briefHistory")}
            </Typography>
          }
        />
        <CardMedia
          component="img"
          image={snippet}
          alt="brief history"
          // sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
      </Card>
      <br />
      <Card variant="outlined" sx={{ height: "auto" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              H
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title="Ambrose, Mt frere"
          subheader="May 18, 2022"
        />
        <CardContent>
          <CardMedia
            component="img"
            // height={600}
            image={bkground}
            alt="Ambrose"
            sx={{ padding: "1em 1em 0 1em", objectFit: "fill" }}
          />
        </CardContent>
      </Card>
    </>
  );
}
