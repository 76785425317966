import React, { useEffect, useState } from "react";
import AppRouter from "./AppRouter";
import { BrowserRouter } from "react-router-dom";
import ButtonAppBar from "../Molecules/AppBar";
import { setupTranslations } from "./../../i18n/config";
import { LangaugeProvider } from "../../providers/LangaugeProvider";

const App: React.FC = () => {
  useEffect(() => {
    setupTranslations();
  }, []);

  return (
    <LangaugeProvider>
      <BrowserRouter>
        <ButtonAppBar />
        <AppRouter />
      </BrowserRouter>
    </LangaugeProvider>
  );
};

export default App;
