import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export type Language = {
  value: string;
  display: string;
};
export const Supported_Locales: Language[] = [
  { value: "enUS", display: "English" },
  { value: "xs", display: "Xhosa" },
];

interface LanguageContextValues extends Language {
  upsertLanguage: (lang: Language) => void;
}

type LanguageProviderProps = {
  children: React.ReactNode;
};

const xs = Supported_Locales.find((x) => x.value === "xs");

const contextDefaultValues: LanguageContextValues = {
  value: xs!.value,
  display: xs!.display,
  upsertLanguage: () => {},
};

export const LangaugeContext =
  React.createContext<LanguageContextValues>(contextDefaultValues);

export const LangaugeProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState(xs!);
  //   const { i18n } = useTranslation();

  const upsertLanguage = (lang: Language) => {
    setLanguage(lang);
    // i18n.changeLanguage(lang.value);
  };

  return (
    <LangaugeContext.Provider
      value={{
        value: language.value,
        display: language.display,
        upsertLanguage,
      }}
    >
      {children}
    </LangaugeContext.Provider>
  );
};
