import { Theme } from "@emotion/react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import React from "react";

const useStyles = makeStyles()(() => ({
  mainBody: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    marginRight: `-50%`,
    transform: "translate(-50%, -50%)",
    color: '"#BEC4CA"',
    textAlign: "center",
  },
  mainTitle: {
    fontSize: `38px`,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: `12px`,
  },
}));
export const ComingSoon = () => {
  const { classes: comingSoonStyle } = useStyles();
  return (
    <div className={comingSoonStyle.mainBody}>
      <Typography
        className={comingSoonStyle.mainTitle}
      >{`Coming soon`}</Typography>
      <Typography className={comingSoonStyle.subTitle}>
        {`Thank you for your interest on this content, unfortunately, this page is still under development.`}
      </Typography>
    </div>
  );
};
