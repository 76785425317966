export const history = `OoSokhulu umthombo wabo apho usuka khona kuse mahlabathini. UMajekana wazala uMtshokotsho, uMtshokotsho wazala uMlobeli, uMlobeli yena welanywa nguManxayi, uManxayi yena welanywa yintombazana enguChithekile, uChithekile yena elanywe nguSisilana olithunjana lika Mtshokotsho.
Inxiwa lokuqala loSokhulu lise Mahlabathini elesibini liseMtshezi apho bashiya khona uMajekana noMtshokotsho noManxayi, basuka apho eMtshezi emva kwempi enkulu babheka eliSuthu apho bafika bakha khona, unyana kaMlobeli ongu Siphika wamithisa intombazana yenkosi apho eliSuthu. Yathi inkosi yakhona intombi yam imithiswe litebele. Lamatebele mawahleselwe  abulawe. Ngemini ethile intombazana leyo emithiswe nguSiphika yayohlebela uSiphika wamxelela ukuba kuthiwa mababulawe waza uSiphika waxela uyise uMlobeli. UMlobeli waxelela umzi wonke ukuba sizakuhlaselwa apha ngemini ithile. Kuthe ngalomini kwakugqiba ukurwalala athutha wonke lamadoda nosapho lwawo. Athe ke xa ephumela enkorweni ithile wathi uSiphika hambani mna ndizakusala apha nezibham ezibini.
Wasala nenkosikazi yakhe uMadontsa. Xa kuvela beSuthu wabadubula uSiphika bazamile ukulwa abeSuthu kodwa uthe uSiphika kwakuphela imbumbulu kwisibham ebesisebenzisa wanika inkosizaki esinye isibhum ukuba mayidubule.
Idibulile inkosikazi yakhe nayo baze bajika abeSuthu behlulekile bathi uSiphika uyasigqiba. Bakuba bejikile abeSuthu uSiphika uye walandela abakowabo wayokubafumana endleleni edlula ngankosi uBhede. Unkosi uBhede wathi mabashiye abanye kuba ndiyaluthanda oluhlanga lenu, wabashiya nyani ngaba babizwa ukuba ngabafugeni.
Wathi uMathandela andinako ukwehlukana noMlobeli ndiqhele ukuhamba naye indawo ezinzima ehamba nomtakwabo uSisilana nomzi wonke uphela. Bahamba ke kunye bevundla eMariazel. Badlula eSigoga bafika baphuma eSigoga phantsi kweliwa elibomvu. Bathe xa besuka apho eSigoga beza kulendawo sikuyo ngoku kwaNyaniso.
Bafika kokha amaBhaca phaya kwaSigwenyela, wathi uMlobeli gxothani  lamaBhaca apha emke, bangena kuwo bawabetha kwaliwa apho ade abaleka amaBhaca kwathi kanti kukho elinye iBhaca elalihamba phezu kwamawa elixesha kusiliwa uMrhubela wathi esothuka elaBhaca belihamba phezu 
kwamawa selilapha kufuphi naye lamhlaba ngomkhonto wabona ukuba xa esiya emva lizakumlimaza  walibamba bafika abakowabo balibetha eliBhaca, bawabetha ewonke bawaweza engqubusini.
Bayakuwaweza apho kuhlangana khona umlambo iRolo neKinirha bajika babuya ekhaya, bamelapha wangafi emva koko umrhubela xa bebona neBhaca alwe nalo bamkhalime abakubo bemkhalime njalo benqanda, wabona ukuba uMlobeli unyana wakhe uzakuhlala kakubi wamthatha wamsa kwaMmango nje ngokuba belapho abantu bakwa Mlobeli nangoku bavela ngolohlobo wathi kuMmango ndigcinele lomntwana.
Unyana kaSiphika wokuqala ngubheki, uBheki welanywa nguBhekizulu, uMlobeli wayenabafazi abahlanu, uzalo luka Sisilana, Mtshagobo, Rulwana, Mnukwa. Uzalo luka Rulwana, Ndawo, Mgaqu, Sikhukhu, Sidoyi.
Undawo wazala uMakhawula, Gontsi, Gevase
Indlu yesibini kaRulwana, Mkhuzo Sisilana, Juweli Sisilana.
Indlu yesithatu yintombazana.
Indlu yesine nguBenithile ozale uMbulelo Sisilana uMthuthuzeli Sisilan uMzikayise Sisilana.

Injongo zoluzalwano luxela ukuba masazane ukuba Sisonke sizalwa ngumntu omnye nathi masibebanye !!!!!!!!!
`;

export const p1 = `OoSokhulu umthombo wabo apho usuka khona kuse mahlabathini. UMajekana wazala uMtshokotsho, uMtshokotsho wazala uMlobeli, uMlobeli yena welanywa nguManxayi, uManxayi yena welanywa yintombazana enguChithekile, uChithekile yena elanywe nguSisilana olithunjana lika Mtshokotsho.`;
export const p2 = `Inxiwa lokuqala loSokhulu lise Mahlabathini elesibini liseMtshezi apho bashiya khona uMajekana noMtshokotsho noManxayi, basuka apho eMtshezi emva kwempi enkulu babheka eliSuthu apho bafika bakha khona, unyana kaMlobeli ongu Siphika wamithisa intombazana yenkosi apho eliSuthu. Yathi inkosi yakhona intombi yam imithiswe litebele. Lamatebele mawahleselwe  abulawe. Ngemini ethile intombazana leyo emithiswe nguSiphika yayohlebela uSiphika wamxelela ukuba kuthiwa mababulawe waza uSiphika waxela uyise uMlobeli. UMlobeli waxelela umzi wonke ukuba sizakuhlaselwa apha ngemini ithile. Kuthe ngalomini kwakugqiba ukurwalala athutha wonke lamadoda nosapho lwawo. Athe ke xa ephumela enkorweni ithile wathi uSiphika hambani mna ndizakusala apha nezibham ezibini.`;
export const p3 = `Wasala nenkosikazi yakhe uMadontsa. Xa kuvela beSuthu wabadubula uSiphika bazamile ukulwa abeSuthu kodwa uthe uSiphika kwakuphela imbumbulu kwisibham ebesisebenzisa wanika inkosizaki esinye isibhum ukuba mayidubule.`;
export const p4 = `Idibulile inkosikazi yakhe nayo baze bajika abeSuthu behlulekile bathi uSiphika uyasigqiba. Bakuba bejikile abeSuthu uSiphika uye walandela abakowabo wayokubafumana endleleni edlula ngankosi uBhede. Unkosi uBhede wathi mabashiye abanye kuba ndiyaluthanda oluhlanga lenu, wabashiya nyani ngaba babizwa ukuba ngabafugeni.`;
export const p5 = `Wathi uMathandela andinako ukwehlukana noMlobeli ndiqhele ukuhamba naye indawo ezinzima ehamba nomtakwabo uSisilana nomzi wonke uphela. Bahamba ke kunye bevundla eMariazel. Badlula eSigoga bafika baphuma eSigoga phantsi kweliwa elibomvu. Bathe xa besuka apho eSigoga beza kulendawo sikuyo ngoku kwaNyaniso.`;
export const p6 = `Bafika kokha amaBhaca phaya kwaSigwenyela, wathi uMlobeli gxothani  lamaBhaca apha emke, bangena kuwo bawabetha kwaliwa apho ade abaleka amaBhaca kwathi kanti kukho elinye iBhaca elalihamba phezu kwamawa elixesha kusiliwa uMrhubela wathi esothuka elaBhaca belihamba phezukwamawa selilapha kufuphi naye lamhlaba ngomkhonto wabona ukuba xa esiya emva lizakumlimaza  walibamba bafika abakowabo balibetha eliBhaca, bawabetha ewonke bawaweza engqubusini. `;
export const p7 = `Bayakuwaweza apho kuhlangana khona umlambo iRolo neKinirha bajika babuya ekhaya, bamelapha wangafi emva koko umrhubela xa bebona neBhaca alwe nalo bamkhalime abakubo bemkhalime njalo benqanda, wabona ukuba uMlobeli unyana wakhe uzakuhlala kakubi wamthatha wamsa kwaMmango nje ngokuba belapho abantu bakwa Mlobeli nangoku bavela ngolohlobo wathi kuMmango ndigcinele lomntwana.`;
export const p8 = `Unyana <br>kaSiphika</br> wokuqala ngubheki, uBheki welanywa nguBhekizulu, uMlobeli wayenabafazi abahlanu, uzalo luka Sisilana, Mtshagobo, Rulwana, Mnukwa. Uzalo luka Rulwana, Ndawo, Mgaqu, Sikhukhu, Sidoyi.`;
export const p9 = `Undawo wazala uMakhawula, Gontsi, Gevase.`;
export const p10 = `Indlu yesibini kaRulwana, Mkhuzo Sisilana, Jowel Sisilana.`;
export const p11 = `Indlu yesithatu yintombazana.`;
export const p12 = `Indlu yesine nguBenithile ozale uMbulelo Sisilana uMthuthuzeli Sisilan uMzikayise Sisilana.`;
export const p13 = `Injongo zoluzalwano luxela ukuba masazane ukuba Sisonke sizalwa ngumntu omnye nathi masibebanye !!!!!!!!!`;
