import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import ForestIcon from "@mui/icons-material/Forest";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LanguagePicker } from "./LangaugePicker";

export const drawerWidth = 240;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ButtonAppBar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { t: translateText } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
            {translateText("heritage")}
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <LanguagePicker />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={"home"} disablePadding>
            <ListItemButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={2}>
                  <HomeIcon color="primary" />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={"Home"}
                    onClick={() => {
                      handleDrawerClose();
                      navigate("/");
                    }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
          <ListItem key={"history"} disablePadding>
            <ListItemButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={2}>
                  <TimelineIcon color="secondary" />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={translateText("briefHistory")}
                    onClick={() => {
                      handleDrawerClose();
                      navigate("/history");
                    }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
          <ListItem key={"family-tree"} disablePadding>
            <ListItemButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={2}>
                  <ForestIcon color="success" />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={translateText("lineageTree")}
                    onClick={() => {
                      handleDrawerClose();
                      navigate("/family-tree");
                    }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
          <ListItem key={"customs"} disablePadding>
            <ListItemButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={2}>
                  <AutoStoriesIcon color="warning" />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={translateText("customs")}
                    onClick={() => {
                      handleDrawerClose();
                      navigate("/customs");
                    }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
          <ListItem key={"contact"} disablePadding>
            <ListItemButton>
              <Grid container alignItems={"center"}>
                <Grid item xs={2}>
                  <ContactPageIcon color="info" />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={translateText("contact")}
                    onClick={() => {
                      handleDrawerClose();
                      navigate("/contact");
                    }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
