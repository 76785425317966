import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Type from "../Atoms/Type";
import { DrawerHeader, drawerWidth } from "../Molecules/AppBar";
import HomeCard from "../Molecules/HomeCard";
import { Main } from "../Molecules/MainContent";
const bkground = require("./../../assets/ambrose.jpg");

const HomePage = () => {
  const { t: translateText } = useTranslation();
  return (
    <>
      <Box textAlign={"center"}>
        <Typography variant="h4" pt={5} pb={5}>
          {translateText("introduction")}
        </Typography>
        <Typography paragraph>{translateText("introText1")}</Typography>
        <Typography paragraph>{translateText("introText2")}</Typography>
      </Box>
      <div style={{ padding: "25px 0px 0px 25px", textAlign: "left" }}>
        <Type />
      </div>
      <Main open={true} drawerWidth={drawerWidth}>
        <DrawerHeader />
        <HomeCard />
      </Main>
    </>
  );
};

export default HomePage;
